import axios from 'axios';
import React, { useState } from 'react';
import ProjectForm from './ProjectForm';
import useError from '../../../../hooks/useError';
import { useHistory } from 'react-router';
import Loader from '../../../Loader';

const AddProject = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleError = useError();

  const handleSubmit = async ({ projectData, tag }) => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `/api/projects`,
        {
          infos: projectData,
          tag: tag,
        },
        { withCredentials: true }
      );

      setLoading(false);

      // Redirect to ImageForm
      history.push(`/admin/project/${data.project}/images`);
    } catch (error) {
      handleError(error);
    }
  };

  if (loading) {
    return (
      <div className='container main-page flex'>
        <Loader />
      </div>
    );
  }

  return <ProjectForm project={false} handleSubmit={handleSubmit} />;
};

export default AddProject;
