import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useHistory, Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const AdminNavbar = () => {
  const { isAdmin, setAuth } = useAuth();
  const { pathname } = useLocation();
  const history = useHistory();

  const isLarge = useMediaQuery({ query: '(min-width: 1200px)' });

  const logout = (e) => {
    e.preventDefault();
    setAuth('logout');
  };

  if (!isAdmin) return null;

  return (
    <div className='navbar'>
      <div className='logo pointer' onClick={() => history.push('/')}>
        <img
          src={`${
            process.env.REACT_APP_SERVER_URL
          }/client/icons/claudiabettini-lighting-designer-logo-${
            isLarge ? 'navbar-desktop' : 'navbar'
          }.svg`}
          alt=''
        />
      </div>
      <nav>
        <ul className='montserrat-medium'>
          {pathname === '/admin/dashboard' ? (
            <li>
              <Link to='/admin/addProject'>AGGIUNGI</Link>
            </li>
          ) : (
            <li>
              <Link to='/admin/dashboard'>PORTFOLIO</Link>
            </li>
          )}
          {pathname !== '/admin/messages' && (
            <li>
              <Link to='/admin/messages'>MESSAGGI</Link>
            </li>
          )}

          <li>
            <Link to='#' onClick={logout}>
              ESCI
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default AdminNavbar;
