import React, { useEffect, useState } from 'react';
import useValidation from '../../../../hooks/useValidation';
import useError from '../../../../hooks/useError';
import CustomInput from '../../../CustomInput';
import * as yup from 'yup';
import axios from 'axios';
import Loader from '../../../Loader';

const FileForm = ({ image, order, dispatch, projectId, sent }) => {
  const [loading, setLoading] = useState(false);

  const handleError = useError();

  // Initialize description, width and height of image
  // (Otherwise their initial value would be null and
  // the input uncontrolled)
  const initialValues = {
    alt: image.alt || '',
    width: image.width || '',
    height: image.height || '',
  };

  const form = useValidation(initialValues);

  const schema = {
    alt: yup.string().required(),
    width: yup.number().required().positive().integer(),
    height: yup.number().required().positive().integer(),
  };

  useEffect(() => {
    const runValidation = async () => {
      await form.onSubmit(schema);
    };

    runValidation();
  }, [sent]);

  // Set description, width and height of image
  const handleChange = (name, value, schema) => {
    dispatch({
      type: 'change',
      payload: {
        ...image,
        [name]: value,
      },
    });
    form.onChange(name, value, schema);
  };

  // Change image order
  const changeOrder = (src, newIndex) => {
    dispatch({
      type: 'reorder',
      payload: {
        src,
        newIndex,
      },
    });
  };

  // Delete image
  const handleDelete = async (image) => {
    setLoading(true);
    try {
      await axios.delete(`/api/projects/${projectId}/images/${image._id}`, {
        withCredentials: true,
      });
      dispatch({ type: 'delete', payload: image });
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const inputProps = (name) => {
    return {
      name,
      handleChange: handleChange,
      handleBlur: form.onBlur,
      isValid: form.isValid(name),
      value: image[name] || '',
    };
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      className={`file-form ${
        order.indexOf(image.src) === 0 ? 'thumbnail' : null
      }`}
      key={image.src}
    >
      <img src={`/images/${image.src}`} alt={image.src} />
      <p className='montserrat-light'>{image.src}</p>
      <div>
        {order.indexOf(image.src) !== 0 && (
          <React.Fragment>
            <CustomInput
              label='Descrizione'
              {...inputProps('alt')}
              schema={schema.alt}
            />

            <div className='grid'>
              <CustomInput
                label='Larghezza'
                text
                style={{ width: '50%', paddingLeft: '3px' }}
                {...inputProps('width')}
                schema={schema.width}
              />

              <CustomInput
                label='Altezza'
                text
                {...inputProps('height')}
                style={{ width: '50%', paddingLeft: '3px' }}
                schema={schema.height}
              />
            </div>
          </React.Fragment>
        )}

        <div className='grid'>
          <button onClick={() => handleDelete(image)} className='button'>
            &#10008; Elimina
          </button>
          <div className='select-container'>
            <label htmlFor='order' className='montserrat-light'>
              Ordine:
            </label>
            <select
              name='order'
              onChange={(e) =>
                changeOrder(image.src, parseInt(e.target.value, 10))
              }
              value={order.indexOf(image.src)}
              className='select-input'
            >
              {order.map((src, index) => {
                // Thumbnail is the first src of order array
                if (index === 0) {
                  return (
                    <option value={index} key={src}>
                      Thumbnail
                    </option>
                  );
                }

                return (
                  <option value={index} key={src}>
                    {index}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileForm;
