import React, { useState } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import useValidation from '../../../hooks/useValidation';
import CustomInput from '../../CustomInput';
import { useHistory } from 'react-router-dom';
import Loader from '../../Loader';

const ContactForm = () => {
  const history = useHistory();
  const [status, setStatus] = useState('idle');
  const [consent, setConsent] = useState(false);
  const [checkConsent, setCheckConsent] = useState(false);

  const initialValues = {
    email: '',
    object: '',
    message: '',
  };
  const form = useValidation(initialValues);

  const schema = {
    email: yup.string().email().required(),
    object: yup.string().required(),
    message: yup.string().required(),
  };

  const toggleCheckbox = () => {
    setCheckConsent(true);
    setConsent((consent) => setConsent(!consent));
  };

  const customCheckboxClasses = () => {
    if (checkConsent && !consent) return 'custom-checkbox invalid';
    if (consent) return 'custom-checkbox valid';
    return 'custom-checkbox';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const canSubmit = await form.onSubmit(schema);
    setCheckConsent(true);

    if (canSubmit && consent) {
      setStatus('loading');
      try {
        await axios.post('/api/messages', form.values);
        setStatus('sent');
      } catch (error) {
        history.push('/error');
      }
    }
  };

  const inputProps = (name) => {
    return {
      name,
      handleChange: form.onChange,
      handleBlur: form.onBlur,
      isValid: form.isValid(name),
      value: form.values[name],
    };
  };

  if (status === 'idle') {
    return (
      <div>
        <form onSubmit={handleSubmit} className='montserrat-light'>
          <CustomInput
            label='Email'
            text
            schema={schema.email}
            {...inputProps('email')}
          />
          <CustomInput
            label='Oggetto'
            text
            schema={schema.object}
            {...inputProps('object')}
          />
          <CustomInput
            label='Messaggio'
            text={false}
            schema={schema.message}
            {...inputProps('message')}
          />
          <div className='consent flex montserrat-light'>
            <div
              className={customCheckboxClasses()}
              onClick={toggleCheckbox}
            ></div>
            <label
              htmlFor='consent-checkbox'
              className={checkConsent && !consent ? 'invalid-label' : null}
            >
              <input
                type='checkbox'
                name='consent-checkbox'
                id='consent-checkbox'
                value={consent}
                onClick={toggleCheckbox}
                onBlur={() => setCheckConsent(true)}
              />
              Autorizzo al trattamento dei dati personali in base alla{' '}
              <a href='/privacy' className='poppins'>
                privacy policy
              </a>
              .
            </label>
          </div>

          <div className='flex'>
            <button className='montserrat button' type='submit'>
              Invia
            </button>
          </div>
        </form>
      </div>
    );
  } else if (status === 'loading') {
    return <Loader />;
  } else if (status === 'sent') {
    return (
      <p className="montserrat">
        Il tuo messaggio è stato inviato. <br />
      </p>
    );
  }
};

export default ContactForm;
