import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useAuth from '../../hooks/useAuth';
import useError from '../../hooks/useError';
import Loader from '../Loader';

const MessagesScreen = () => {
  const { redirectUnauthenticated } = useAuth();
  const handleError = useError();
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState('');

  redirectUnauthenticated();

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const res = await axios.get('/api/messages');
        setMessages(res.data.messages);
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    };
    fetchMessages();
  }, []);

  const handleClick = (id) => {
    // Click on infos to toggle message
    if (id === open) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  if (loading) {
    return (
      <div className='container main-page flex'>
        <Loader />
      </div>
    );
  } else {
    return (
      <div className='container main-page'>
        {messages.length > 0 ? (
          <div className='messages'>
            <div className='title'>
              <h3 className='montserrat-medium'>MITTENTE</h3>
            </div>
            <div className='title'>
              <h3 className='montserrat-medium'>OGGETTO</h3>
            </div>
            <div className='title'>
              <h3 className='montserrat-medium'>DATA</h3>
            </div>
            {messages.map((message) => {
              return (
                <React.Fragment key={message._id}>
                  <p
                    className='montserrat row'
                    onClick={() => handleClick(message._id)}
                  >
                    {message.email}
                  </p>
                  <p
                    className='montserrat row'
                    onClick={() => handleClick(message._id)}
                  >
                    {message.object}
                  </p>
                  <p
                    className='montserrat row'
                    onClick={() => handleClick(message._id)}
                  >
                    {new Date(message.createdAt).toLocaleDateString('en-gb')}
                  </p>
                  {open === message._id && (
                    <p className='montserrat-light row-span'>
                      {message.message}
                    </p>
                  )}
                  <div className='row-span separator'></div>
                </React.Fragment>
              );
            })}
          </div>
        ) : (
          <div className='flex pt-large'>
            <p className='montserrat-medium'>
              Ooops, sembra che qui non ci sia ancora nulla
            </p>
          </div>
        )}
      </div>
    );
  }
};

export default MessagesScreen;
