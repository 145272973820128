import React from 'react';

const Loader = () => {
  return (
    <img
      src={`${process.env.REACT_APP_SERVER_URL}/client/icons/loader.gif`}
      alt='Loading...'
    />
  );
};

export default Loader;
