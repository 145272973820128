import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectTag, setTag } from '../redux/tagSlice';

const Tags = ({ redirect }) => {
  const history = useHistory();
  const tag = useSelector(selectTag);
  const dispatch = useDispatch();

  const handleClick = (tag) => {
    dispatch(setTag(tag));
    if (redirect) history.push('/portfolio');
  };

  return (
    <div className='choices-container'>
      <p
        className={`choices pointer montserrat ${
          tag === 'personal' ? 'selected' : undefined
        }`}
        onClick={() => handleClick('personal')}
      >
        progetti
      </p>
      <p
        className={`choices pointer montserrat ${
          tag === 'simulation' ? 'selected' : undefined
        }`}
        onClick={() => handleClick('simulation')}
      >
        simulazioni
      </p>
      <p
        className={`choices pointer montserrat ${
          tag === 'collaboration' ? 'selected' : undefined
        }`}
        onClick={() => handleClick('collaboration')}
      >
        collaborazioni
      </p>
    </div>
  );
};

export default Tags;
