import { createSlice } from '@reduxjs/toolkit';

export const tagSlice = createSlice({
  name: 'tag',
  initialState: {
    tag: 'personal',
  },
  reducers: {
    setTag: (state, action) => {
      state.tag = action.payload;
    },
  },
});

export const { setTag } = tagSlice.actions;

export default tagSlice.reducer;

export const selectTag = (state) => state.tag.tag;
