import React from 'react';
import { useHistory } from 'react-router';

const ProjectCard = ({ project, tag }) => {
  const history = useHistory();
  const onClick = () => {
    history.push(`/portfolio/${tag}/${project._id}`);
  };

  return (
    <div className='pointer'>
      <div>
        <img
          src={`${process.env.REACT_APP_SERVER_URL}/images/${project.thumbnail}`}
          alt={project.infos.title}
          onClick={onClick}
          className='hover-opaque w-100'
        />
      </div>
      <div className='projects-text'>
        <h1 className='montserrat-medium' onClick={onClick}>
          {project.infos.title}
        </h1>
        <h2 className='montserrat-light'>{project.infos.subtitle}</h2>
      </div>
    </div>
  );
};

export default ProjectCard;
