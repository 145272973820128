import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ProjectForm from './ProjectForm';
import useAuth from '../../../../hooks/useAuth';
import useError from '../../../../hooks/useError';
import Loader from '../../../Loader';

const EditProject = () => {
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState({});

  const { id } = useParams();
  const history = useHistory();
  const handleError = useError();
  const { redirectUnauthenticated } = useAuth();

  redirectUnauthenticated();

  // Get project from DB
  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axios.get(`/api/projects/${id}`, {
          withCredentials: true,
        });
        setProject(data);
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    }
    fetchData();
  }, [id]);

  const handleSubmit = async ({ projectData, tag }) => {
    setLoading(true);
    try {
      await axios.put(
        `/api/projects/${id}`,
        {
          infos: projectData,
          tag: tag,
        },
        { withCredentials: true }
      );

      setLoading(false);

      // Redirect to ImageForm
      history.push(`/admin/project/${id}/images`);
    } catch (error) {
      handleError(error);
    }
  };

  if (loading) {
    return (
      <div className='container main-page flex'>
        <Loader />
      </div>
    );
  } else {
    return <ProjectForm project={project} handleSubmit={handleSubmit} />;
  }
};

export default EditProject;
