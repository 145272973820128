import React from 'react';
import MediaQuery from 'react-responsive';
import ContactForm from './ContactForm';

const ContactsScreen = () => {
  return (
    <div className='container content contacts main-page'>
      <div className='grid'>
        <div>
          <div className='contacts-logo'>
            <img
              src={`${process.env.REACT_APP_SERVER_URL}/client/icons/claudiabettini-lighting-designer-logo-home.svg`}
              alt=''
            />
          </div>

          <p className='montserrat'>Dolo, Venezia</p>

          <p className='montserrat'>arch.claudiabettini@gmail.com</p>

          <p className='montserrat'>
            Ordine degli Architetti della Provincia{' '}
            <MediaQuery maxWidth={506}>
              <br />
            </MediaQuery>
            <MediaQuery minWidth={901} maxWidth={1000}>
              <br />
            </MediaQuery>
            di Venezia n. 4842
          </p>

          <p className='montserrat'>P. IVA 04479040273</p>

          <div className='contacts-icons'>
            <a href='https://www.facebook.com/profile.php?id=100077310455373'>
              {' '}
              <img
                src={`${process.env.REACT_APP_SERVER_URL}/client/icons/facebook-blue.svg`}
                alt=''
              />
            </a>

            <a href='https://www.instagram.com/claudiabettini.lightingdesign/'>
              {' '}
              <img
                src={`${process.env.REACT_APP_SERVER_URL}/client/icons/instagram-blue.svg`}
                alt=''
              />
            </a>
            <a href='https://www.linkedin.com/in/claudia-bettini-2b1312142/'>
              {' '}
              <img
                src={`${process.env.REACT_APP_SERVER_URL}/client/icons/linkedin-blue.svg`}
                alt=''
              />
            </a>
            <a href='https://www.pinterest.it/archclaudiabettini/zona-giorno-moderna/'>
              {' '}
              <img
                src={`${process.env.REACT_APP_SERVER_URL}/client/icons/pinterest-blue.svg`}
                alt=''
              />
            </a>
          </div>
        </div>
        <ContactForm />
      </div>
    </div>
  );
};

export default ContactsScreen;
