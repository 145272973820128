import React from 'react';
import { Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useGetProjectsQuery } from '../../../redux/api';
import { selectTag } from '../../../redux/tagSlice';

import Loader from '../../Loader';
import ProjectCard from './ProjectCard';
import Tags from '../../Tags';

const ProjectsScreen = () => {
  const tag = useSelector(selectTag);

  const { data: projects, error } = useGetProjectsQuery();

  if (projects)
    return (
      <div className='project-container main-page'>
        <Tags />
        <div className='projects grid'>
          {projects[tag].length > 0 &&
            projects[tag].map((project) => {
              return (
                <ProjectCard project={project} tag={tag} key={project._id} />
              );
            })}
        </div>
      </div>
    );

  if (error) return <Redirect push to={'/error'} />;

  return (
    <div className='container main-page content flex'>
      <Loader />
    </div>
  );
};

export default ProjectsScreen;
