import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useHistory } from 'react-router-dom';
import CustomInput from '../../../CustomInput';
import get from 'lodash.get';
import useValidation from '../../../../hooks/useValidation';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { selectTag, setTag } from '../../../../redux/tagSlice';

const ProjectForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  // FORM STATE

  const initialValues = {
    title: get(props.project, 'infos.title', ''),
    subtitle: get(props.project, 'infos.subtitle', ''),
    description: get(props.project, 'infos.description', ''),
  };

  const form = useValidation(initialValues);

  const schema = {
    title: yup.string().required(),
    subtitle: yup.string().required(),
    description: yup.string().required(),
  };

  const tag = useSelector(selectTag);

  // SUBMIT FUNCTION

  const handleSubmit = async (e) => {
    e.preventDefault();

    // VALIDATION
    const canSubmit = await form.onSubmit(schema);

    if (canSubmit) props.handleSubmit({ projectData: form.values, tag: tag });
  };

  // INPUT PROPS

  const inputProps = (name) => {
    return {
      name,
      handleChange: form.onChange,
      handleBlur: form.onBlur,
      isValid: form.isValid(name),
      value: form.values[name],
    };
  };

  return (
    <div className='container content admin main-page'>
      <div className='flex'>
        <form onSubmit={handleSubmit}>
          <CustomInput
            label='Titolo'
            text
            schema={schema.title}
            {...inputProps('title')}
          />
          <CustomInput
            label='Sottotitolo'
            text
            schema={schema.subtitle}
            {...inputProps('subtitle')}
          />
          <CustomInput
            label='Descrizione'
            text={false}
            schema={schema.description}
            {...inputProps('description')}
          />
          <div className='pb-small'>
            <label className='montserrat-light'>Anteprima</label>
            <ReactMarkdown className='montserrat-light'>
              {form.values.description}
            </ReactMarkdown>
          </div>
          <div className='select-container'>
            <label htmlFor='tag' className='montserrat-light'>
              Tipo:
            </label>
            <select
              name='tag'
              onChange={(e) => dispatch(setTag(e.target.value))}
              value={tag}
              className='select-input'
            >
              <option value='personal'>Progetto</option>
              <option value='simulation'>Simulazione</option>
              <option value='collaboration'>Collaborazione</option>
            </select>
          </div>

          <div className='flex pt-small space-around'>
            <button
              className='montserrat button'
              onClick={() => history.push('/admin/dashboard')}
            >
              Annulla
            </button>
            <button className='montserrat button' type='submit'>
              Avanti
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProjectForm;
