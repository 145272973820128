import React from 'react';

const PrivacyScreen = () => {
  return (
    <div className='container main-page content privacy'>
      <h1 className='montserrat'>Privacy Policy</h1>
      <p className='montserrat-light mt-20'>
        La presente informativa spiega come Claudia Bettini, proprietaria del
        sito www.claudiabettini.it, utilizza i dati personali raccolti
        dall'utente del sito stesso.
      </p>
      <h3 className='montserrat'>Informazioni sui dati raccolti</h3>
      <p className='montserrat-light'>
        Questo sito web, tramite volontaria compilazione di un form, raccoglie
        l'indirizzo email inserito dall'utente, al fine esclusivo di poter
        contattare nuovamente l'utente se necessario e/o richiesto.
      </p>
      <p className='montserrat-light'>
        I dati vengono conservati indefinitamente tramite il servizio di cloud
        database MongoDB Atlas. Puoi leggere il DPA di questo servizio cliccando
        su{' '}
        <a
          href='https://www.mongodb.com/legal/dpa'
          target='_blank'
          rel='noopener noreferrer'
          className='poppins'
        >
          questo link
        </a>
        .
      </p>
      <h3 className='montserrat'>Diritti dell'utente</h3>
      <ul className='montserrat-light'>
        <li>Diritto di accesso ai dati</li>
        <li>Diritto di rettifica dei dati</li>
        <li>Diritto di cancellazione dei dati</li>
        <li>Diritto di restringere l'elaborazione dei dati</li>
        <li>Diritto di obiettare all'elaborazione dei dati</li>
        <li>Diritto di trasferimento dei dati</li>
      </ul>
      <h3 className='montserrat'>Contatti</h3>
      <p className='montserrat-light'>
        Se intendi esercitare uno dei diritti sopra indicati o hai domande sulla
        nostra privacy policy, puoi contattarci all'indirizzo:
        arch.claudiabettini@gmail.com{' '}
      </p>
    </div>
  );
};

export default PrivacyScreen;
