import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './styles/reset.css';
import './styles/main.css';

import Login from './components/admin/auth/Login';
import Navbar from './components/guests/Navbar';
import HomeScreen from './components/guests/HomeScreen/HomeScreen';
import ProfileScreen from './components/guests/ProfileScreen';
import ContactsScreen from './components/guests/ContactsScreen/ContactsScreen';
import ProjectsScreen from './components/guests/ProjectsScreen/ProjectsScreen';

import AddProject from './components/admin/projects/ProjectForm/AddProject';
import EditProject from './components/admin/projects/ProjectForm/EditProject';
import ImageForm from './components/admin/projects/ImageForm/ImageForm';
import Dashboard from './components/admin/projects/Dashboard/Dashboard';
import MessagesScreen from './components/admin/MessagesScreen';
import AdminNavbar from './components/admin/AdminNavbar';

import ErrorScreen from './components/ErrorScreen';
import ProjectPage from './components/guests/ProjectPage/ProjectPage';
import PrivacyScreen from './components/guests/PrivacyScreen';
import ScrollUp from './components/guests/ScrollUp';
import Footer from './components/guests/Footer';
import ErrorBoundary from './components/ErrorBoundary';
import ScrollOnRouteChange from './components/ScrollOnRouteChange';
function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Router>
          <ScrollOnRouteChange />
          <Switch>
            {/* GUEST - PUBLIC ROUTES */}
            <Route path='/' exact>
              <Navbar />
              <HomeScreen />
              <ScrollUp />
              <Footer />
            </Route>
            <Route path='/profilo' exact>
              <Navbar />
              <ProfileScreen />
              <ScrollUp />
              <Footer />
            </Route>
            <Route path='/portfolio' exact>
              <Navbar />
              <ProjectsScreen />
              <ScrollUp />
              <Footer />
            </Route>
            <Route path='/portfolio/:tag/:id' exact>
              <Navbar />
              <ProjectPage />
              <ScrollUp />
              <Footer />
            </Route>
            <Route path='/contatti' exact>
              <Navbar />
              <ContactsScreen />
              <ScrollUp />
              <Footer />
            </Route>
            <Route path='/privacy' exact>
              <Navbar />
              <PrivacyScreen />
              <Footer />
            </Route>

            {/* ADMIN - PRIVATE ROUTES */}
            <Route path='/admin' exact>
              <Login />
            </Route>
            <Route path='/admin/dashboard' exact>
              <AdminNavbar />
              <Dashboard />
            </Route>
            <Route path='/admin/addProject' exact>
              <AdminNavbar />
              <AddProject />
            </Route>
            <Route path='/admin/project/:id/images' exact>
              <AdminNavbar />
              <ImageForm />
            </Route>
            <Route path='/admin/editProject/:id' exact>
              <AdminNavbar />
              <EditProject />
            </Route>
            <Route path='/admin/messages' exact>
              <AdminNavbar />
              <MessagesScreen />
            </Route>

            {/* ERROR SCREEN*/}
            <Route path='/error' exact>
              <ErrorScreen />
              <Footer />
            </Route>
            <Route path='*'>
              <ErrorScreen />
              <Footer />
            </Route>
          </Switch>
        </Router>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
