import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

export default function useAuth() {
  const history = useHistory();
  const [auth, setState] = useState(sessionStorage.getItem('auth') || false);

  const logout = () => {
    // Remove status from session storage
    sessionStorage.clear();
    setState(false);
    history.push('/admin');
  };

  const setAuth = async (action) => {
    if (action === 'login') {
      // Save status on session storage
      sessionStorage.setItem('auth', true);
      setState(true);
      history.push('/admin/dashboard');
    } else if (action === 'logout') {
      logout();
      // Send request to server to remove session cookies
      try {
        await axios.post('/api/logout', null, {
          withCredentials: true,
        });
      } catch (error) {
        history.push('/error');
      }
    } else if (action === 'authError') {
      logout();
    }
  };

  const redirectUnauthenticated = () => {
    if (!auth) history.push('/admin');
  };

  return { isAdmin: auth, redirectUnauthenticated, setAuth };
}
