import React from 'react';
import Slider from 'react-slick';

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className}>
      <img
        src={`${process.env.REACT_APP_SERVER_URL}/client/icons/right-arrow.svg`}
        onClick={onClick}
        alt='next'
        className='w-100'
      />
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className}>
      <img
        src={`${process.env.REACT_APP_SERVER_URL}/client/icons/left-arrow.svg`}
        onClick={onClick}
        alt='previous'
        className='w-100'
      />
    </div>
  );
}

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  dots: false,
  draggable: false,
  swipe: true,
  swipeToSlide: true,
  cssEase: 'linear',
  adaptiveHeight: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1080,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
};

const Slides = (props) => {
  return <Slider {...settings}>{props.children}</Slider>;
};

export default Slides;
