import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `/api`,
  }),
  endpoints: (builder) => ({
    getProjects: builder.query({
      query: () => '/projects',
      keepUnusedDataFor: 600, // 10 minutes
    }),
  }),
});

export const { useGetProjectsQuery } = api;
