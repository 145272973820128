import React from 'react';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';

const Title = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });

  const getImages = (screen) => {
    let images = [];
    for (let i = 0; i < 4; i++) {
      images.push(
        <div key={i}>
          <img
            src={`${
              process.env.REACT_APP_SERVER_URL
            }/client/home/claudiabettini-lighting-designer-home-${screen}-0${
              i + 1
            }.jpg`}
            alt=''
            className='w-100 opaque'
          />
        </div>
      );
    }
    return images;
  };

  return (
    <div className='title-container'>
      <Slider
        slidesToShow={1}
        slidesToScroll={1}
        speed={1000}
        infinite={true}
        arrows={false}
        autoplay={true}
        autoplaySpeed={5000}
        dots={false}
        draggable={false}
        pauseOnHover={false}
        swipe={true}
        swipeToSlide={true}
        touchMove={false}
      >
        {isTabletOrMobile ? getImages('mobile') : getImages('desktop')}
      </Slider>
      <div className='title'>
        <h1 className='poppins'>
          <span className='poppins'>c</span>laudia
          <span className='poppins'>b</span>ettini
        </h1>
        <h2 className='montserrat-light subtitle'>
          Architetto Lighting Designer
        </h2>
      </div>
    </div>
  );
};

export default Title;
