import { configureStore } from '@reduxjs/toolkit';
import tagReducer from './tagSlice';
import { api } from './api';

export default configureStore({
  reducer: {
    tag: tagReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});
