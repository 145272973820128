import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { setTag } from '../../redux/tagSlice';

const ProfileScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const redirectToProjects = (e, tag) => {
    e.preventDefault();
    dispatch(setTag(tag));
    history.push('/portfolio');
  };

  return (
    <div className='container main-page profile'>
      <div className='grid'>
        <div className='profile-image flex '>
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/client/profile/claudiabettini-lighting-designer-profile-image.png`}
            alt='ritratto'
          />
        </div>
        <div className='montserrat-light'>
          <div className='profile-logo'>
            <img
              src={`${process.env.REACT_APP_SERVER_URL}/client/icons/claudiabettini-lighting-designer-logo-home.svg`}
              className='w-100'
              alt='logo'
            />
          </div>

          <p>
            La consapevolezza sviluppata negli anni che la <strong>luce</strong>{' '}
            arricchisce la percezione dello spazio, trasforma i luoghi,
            valorizza gli elementi, rende le circostanze stimolanti mi ha
            portata a seguire un percorso specialistico in{' '}
            <strong>lighting design</strong>, attraverso conoscenze tecniche e
            compositive acquisite grazie a esperienze formative e lavorative
            diverse.
          </p>
          <p>
            Nel 2018 intraprendo la <strong>libera professione</strong> di
            architetto lighting designer sviluppando{' '}
            <Link
              to='/portfolio'
              onClick={(e) => redirectToProjects(e, 'personal')}
              className='poppins'
            >
              progetti di illuminazione
            </Link>{' '}
            per abitazioni private, ambienti di lavoro e valorizzazioni
            illuminotecniche.
          </p>
          <p>Luce &#232; emozione e precisione, tecnica e poesia.</p>
          <div className='curriculum-link'>
            <a
              href={`${process.env.REACT_APP_SERVER_URL}/client/profile/claudiabettini-lighting-designer-curriculum.pdf`}
              target='_blank'
              rel='noreferrer noopener'
              className='poppins'
            >
              curriculum
            </a>
          </div>
        </div>
      </div>
      <div className='montserrat-light pt-small'>
        <p>
          Frequento la{' '}
          <strong>Facolt&#224; di Architettura IUAV di Venezia</strong>,
          iniziando nel 2013 il percorso formativo presso lo studio di lighting
          design{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://eclairagiste-geneve.com/'
            className='poppins'
          >
            &#201;clairagiste Zissis Nasioutzikis
          </a>{' '}
          di Ginevra e successivamente approfondendo l'aspetto relativo
          all'illuminazione pubblica per la valorizzazione urbana.
        </p>
        <p>
          Nel 2016 sviluppo la{' '}
          <a
            href={`${process.env.REACT_APP_SERVER_URL}/client/profile/claudiabettini-lighting-designer-tesi.pdf`}
            target='_blank'
            rel='noreferrer noopener'
            className='poppins'
          >
            tesi di laurea specialistica
          </a>{' '}
          "Applicazione del Piano della Luce di Bassano del Grappa al caso
          studio di Viale dei Martiri" nella quale affronto dal punto di vista
          progettuale la tematica dell'eccesiva luminanza delle facciate degli
          edifici prospicienti Viale dei Martiri e il relativo inquinamento
          luminoso generato dagli stessi rispetto all’ambiente notturno.
        </p>
        <p>
          Conclusi gli studi universitari proseguo la specializzazione in
          lighting design dedicando un intenso anno di percorso formativo presso
          <a
            href='http://www.studiopasetti.it/'
            target='_blank'
            rel='noreferrer noopener'
            className='poppins'
          >
            {' '}
            Studio Pasetti Lighting{' '}
          </a>
          di Treviso, che mi porta ad acquisire conoscenze tecniche e formali in
          tutti gli aspetti della progettazione illuminotecnica, dal livello
          preliminare a quello esecutivo. Dal 2018 inizia la collaborazione come
          lighting designer libero professionista con Studio Pasetti Lighting,
          partecipando allo sviluppo di{' '}
          <Link
            to='/portfolio'
            onClick={(e) => redirectToProjects(e, 'collaboration')}
            className='poppins'
          >
            prestigiosi progetti in ambito museale
          </Link>
          .
        </p>
        <p>
          Resto legata alla magia del luogo in cui ho immaginato la luce come
          una professione e in cui ho la possibilità di confronto diretto con il
          cliente, realizzando{' '}
          <Link
            to='/portfolio'
            onClick={(e) => redirectToProjects(e, 'simulation')}
            className='poppins'
          >
            simulazioni e verifiche illuminotecniche
          </Link>{' '}
          a supporto della vendita per il negozio{' '}
          <a
            href='http://www.spaziolucesnc.it/'
            target='_blank'
            rel='noreferrer noopener'
            className='poppins'
          >
            {' '}
            Spazio Luce
          </a>{' '}
          di Dolo.
        </p>
      </div>
    </div>
  );
};

export default ProfileScreen;
