import axios from 'axios';
import React, { useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import FileForm from './FileForm';
import FileUploader from './FileUploader';
import get from 'lodash.get';
import imagesReducer from './imagesReducer';
import * as yup from 'yup';
import useError from '../../../../hooks/useError';
import Loader from '../../../Loader';

// IMAGES STATE

const getImages = (project) => {
  // Get images array from project and add thumbnail (editProject
  // mode) or initialize an empty array if undefined (addProject mode)
  if (project?.thumbnail) {
    return [
      {
        src: project.thumbnail,
        order: 0,
      },
    ].concat(get(project, 'images', []));
  } else {
    return get(project, 'images', []);
  }
};

const initialState = (project) => {
  return {
    images: getImages(project),
    // Save only image sources in correct order
    order: getImages(project)
      .sort((a, b) => a?.order > b?.order)
      .map((image) => image.src),
  };
};

// VALIDATION SCHEMA

const schema = yup.object().shape({
  thumbnail: yup.string().required(),
  images: yup
    .array()
    .of(
      yup.object().shape({
        src: yup.string().required(),
        alt: yup.string().required(),
        width: yup.number().required().positive().integer(),
        height: yup.number().required().positive().integer(),
        order: yup.number().required().positive().integer(),
      })
    )
    .required(),
});

const ImageForm = () => {
  const history = useHistory();
  const handleError = useError();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [sent, setSent] = useState(0);
  const [state, dispatch] = useReducer(imagesReducer, {});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`/api/projects/${params.id}`);
        dispatch({ type: 'init', payload: initialState(data) });
        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    };
    fetchData();
  }, [params.id]);

  // SUBMIT FUNCTION

  const handleSubmit = async (e) => {
    e.preventDefault();

    // PREPARE PROJECT OBJECT
    // Remove thumbnail (the first image in the order array)
    const thumbnail = state.order[0];
    const imagesWithoutThumbnail = state.images.filter((image) => {
      return image.src !== thumbnail;
    });
    // Remove id and change order property
    const preparedImages = imagesWithoutThumbnail.map((image) => {
      return {
        alt: image.alt,
        src: image.src,
        width: image.width,
        height: image.height,
        order: state.order.indexOf(image.src),
      };
    });

    const images = {
      images: preparedImages,
      thumbnail,
    };

    // VALIDATION
    const formIsValid = await schema.isValid(images);
    setSent((sent) => sent++);

    if (formIsValid && state.images.length > 0) {
      setLoading(true);
      try {
        await axios.put(
          `/api/projects/${params.id}/images`,
          {
            project: images,
          },
          {
            withCredentials: true,
          }
        );
        setLoading(false);
        // Redirect to dashboard
        history.push('/admin/dashboard');
      } catch (error) {
        handleError(error);
      }
    }
  };

  if (loading) {
    return (
      <div className='container main-page flex'>
        <Loader />
      </div>
    );
  }

  return (
    <div className='container content main-page'>
      <div className='flex'>
        <form onSubmit={handleSubmit}>
          <div className='grid images-grid'>
            {state.images.length > 0 &&
              state.images
                .sort(
                  (a, b) =>
                    state.order.indexOf(a.src) > state.order.indexOf(b.src)
                )
                .map((image) => {
                  return (
                    <FileForm
                      key={image.src}
                      image={image}
                      order={state.order}
                      dispatch={dispatch}
                      projectId={params.id}
                      sent={sent}
                    />
                  );
                })}
          </div>

          <FileUploader
            projectId={params.id}
            addImage={(payload) => dispatch({ type: 'add', payload })}
          />

          <div className='flex pt-small space-around image-form-buttons'>
            <button
              className='montserrat button'
              onClick={() => history.push(`/admin/editProject/${params.id}`)}
            >
              Indietro
            </button>
            <button
              className='montserrat button'
              type='submit'
              disabled={state.images.length < 1}
            >
              Salva
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ImageForm;
