import React from 'react';
import Slides from './Slides';
import { useHistory } from 'react-router';

const ProjectsSlides = () => {
  const history = useHistory();

  const redirectToProjects = () => {
    history.push('/portfolio');
  };

  const getSlides = () => {
    let slides = [];
    for (let i = 0; i < 10; i++) {
      slides.push(
        <div key={i}>
          <div className='slide pointer' onClick={redirectToProjects}>
            <img
              src={`${
                process.env.REACT_APP_SERVER_URL
              }/client/projects/claudiabettini-lighting-designer-square-${
                i + 1
              }.jpg`}
              alt=''
              className='w-100'
            />
          </div>
        </div>
      );
    }
    return slides;
  };

  return (
    <div className='projects-slides'>
      <h2 className='montserrat-medium'>portfolio</h2>
      <Slides>{getSlides()}</Slides>
    </div>
  );
};

export default ProjectsSlides;
