import React from 'react';
import { useHistory } from 'react-router-dom';

const ProjectCard = ({ project, ...props }) => {
  const history = useHistory();

  const getOptions = () => {
    let options = [];
    for (let i = 0; i < props.totalProjects; i++) {
      options.push(
        <option value={i} key={i}>
          {i + 1}
        </option>
      );
    }
    return options;
  };

  if (project.infos.title) {
    return (
      <div className='grid project-card mt-20' key={project._id}>
        <img
          src={`${process.env.REACT_APP_SERVER_URL}/images/${project.thumbnail}`}
          alt={project.infos.title}
          className='pointer w-100'
          onClick={() => {
            history.push(`/admin/editProject/${project._id}`);
          }}
        />
        <div>
          <h1
            className='montserrat-medium pointer'
            onClick={() => {
              history.push(`/admin/editProject/${project._id}`);
            }}
          >
            {project.infos.title}{' '}
          </h1>
          <div>
            <button
              className='montserrat button'
              onClick={() => props.changeVisibility(project)}
            >
              {project.visible ? 'Nascondi' : 'Pubblica'}
            </button>
          </div>
          {project.visible && (
            <div className='button-order'>
              <select
                name='order'
                onChange={(e) =>
                  props.changeOrder(project, parseInt(e.target.value, 10))
                }
                value={props.order}
                className='select-input'
              >
                {getOptions()}
              </select>
            </div>
          )}

          <div className='button-delete'>
            <button
              className='montserrat button'
              onClick={() => props.handleDelete(project)}
            >
              Elimina
            </button>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default ProjectCard;
