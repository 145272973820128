import React, { useState } from 'react';
import axios from 'axios';
import useError from '../../../../hooks/useError';
import Loader from '../../../Loader';

const FileUploader = ({ projectId, addImage }) => {
  const [loading, setLoading] = useState(false);

  const handleError = useError();

  const handleUpload = async (e) => {
    const files = [...e.target.files];
    setLoading(true);
    const data = new FormData();
    files.forEach((file) => {
      data.append('files', file);
    });

    try {
      const res = await axios.post(`/api/projects/${projectId}/images`, data, {
        withCredentials: true,
      });
      addImage(res.data);
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  if (!loading) {
    return (
      <div className='flex'>
        <div>
          <label className='file-uploader montserrat'>
            Aggiungi
            <input type='file' onChange={handleUpload} multiple />
          </label>
        </div>
      </div>
    );
  } else {
    return <Loader />;
  }
};

export default FileUploader;
