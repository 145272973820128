import React from 'react';

const CustomInput = ({
  label,
  name,
  value,
  handleChange,
  handleBlur,
  isValid,
  schema,
  style,
  text,
}) => {
  const inputProps = () => {
    return {
      name,
      value,
      style,
      id: name,
      onChange: (e) => handleChange(name, e.target.value, schema),
      onBlur: (e) => handleBlur(name, e.target.value, schema),
      className: `montserrat-light ${isValid ? null : 'invalid'}`,
    };
  };

  return (
    <div>
      <label className='montserrat-light' htmlFor={name}>
        {label}
        {text ? (
          <input
            type='text'
            aria-required='true'
            aria-invalid={isValid ? 'false' : 'true'}
            {...inputProps()}
          />
        ) : (
          <textarea
            rows='5'
            aria-required='true'
            aria-invalid={isValid ? 'false' : 'true'}
            {...inputProps()}
          ></textarea>
        )}

        {}
      </label>
    </div>
  );
};

export default CustomInput;
