export default function imagesReducer(state, action) {
  switch (action.type) {
    case 'init':
      // action.payload: state
      return action.payload;

    case 'add':
      // action.payload: [images]
      return {
        // Add images to images array
        images: state.images.concat(action.payload),
        // Add images ids to order array
        order: state.order.concat(action.payload.map((image) => image.src)),
      };

    case 'change':
      // action.payload: image
      return {
        ...state,
        images: state.images.map((originalImage) => {
          // Change only the image with the same src, leave the others unchanged
          if (action.payload.src === originalImage.src) {
            return action.payload;
          }
          return originalImage;
        }),
      };

    case 'delete':
      // action.payload: image
      return {
        // Remove image from image array
        images: state.images.filter((image) => {
          return image !== action.payload;
        }),
        // Remove image.src from order array
        order: state.order.filter((src) => {
          return src !== action.payload.src;
        }),
      };

    case 'reorder':
      // action.payload: {src, newIndex}
      // Remove id from array
      let newOrder = state.order.filter((src) => {
        return src !== action.payload.src;
      });
      // Insert id in the new position
      newOrder.splice(action.payload.newIndex, 0, action.payload.src);

      return {
        ...state,
        order: newOrder,
      };

    default:
      throw new Error();
  }
}
