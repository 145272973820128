import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

const Footer = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

  const privacyPolicy = (
    <Link to='/privacy' className='poppins privacy-link'>
      Privacy
    </Link>
  );

  return (
    <footer className='footer montserrat-light'>
      <p>
        {' '}
        © Copyright -{' '}
        <a
          href='https://www.claudiabettini.it/'
          target='_blank'
          rel='noreferrer noopener'
          className='poppins'
        >
          {' '}
          Claudia Bettini
        </a>{' '}
        | Tutti i diritti riservati
      </p>

      {isMobile && privacyPolicy}

      <p>
        {!isMobile && privacyPolicy}
        {!isMobile && ' |'} Foto -{' '}
        <a
          href='http://www.mattiacarraro.com/'
          target='_blank'
          rel='noreferrer noopener'
          className='poppins'
        >
          {' '}
          Mattia Carraro
        </a>
        {' | '}
        Sviluppo -{' '}
        <a
          href='https://www.lucabettini.com/'
          target='_blank'
          rel='noreferrer noopener'
          className='poppins'
        >
          Luca Bettini
        </a>
      </p>
    </footer>
  );
};

export default Footer;
