import { useHistory } from 'react-router-dom';
import useAuth from './useAuth';

export default function useError() {
  const history = useHistory();
  const { isAdmin, setAuth } = useAuth();

  const handleError = (err) => {
    if (process.env.REACT_APP_NODE_ENV === 'development') console.log(err);

    if (
      (err.response?.status === 401 || err.response?.status === 403) &&
      isAdmin
    ) {
      setAuth('authError');
      history.push('/admin');
    } else {
      history.push('/error');
    }
  };

  return handleError;
}
