import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';

const Navbar = () => {
  const history = useHistory();
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });
  const isLarge = useMediaQuery({ query: '(min-width: 1200px)' });

  const [open, setOpen] = useState(false);

  const logo_src = `${
    process.env.REACT_APP_SERVER_URL
  }/client/icons/claudiabettini-lighting-designer-logo-${
    isLarge ? 'navbar-desktop' : 'navbar'
  }.svg`;

  const expandNavbar = () => {
    setOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeNavbar = () => {
    setOpen(false);
    document.body.style.overflow = 'visible';
  };

  const menu = (
    <nav>
      <ul
        className={
          open ? 'montserrat-light pt-large flex' : 'montserrat-medium'
        }
      >
        <li>
          <a
            href='/profilo'
            className={
              location.pathname.includes('/profilo') ? 'selected' : undefined
            }
          >
            profilo
          </a>
        </li>
        <li>
          <a
            href='/portfolio'
            className={
              location.pathname.includes('/portfolio') ? 'selected' : undefined
            }
          >
            portfolio
          </a>
        </li>
        <li>
          <a
            href='/contatti'
            className={
              location.pathname.includes('/contatti') ? 'selected' : undefined
            }
          >
            contatti
          </a>
        </li>
      </ul>
    </nav>
  );

  if (open)
    return (
      <div className='navbar-expanded'>
        <div className='navbar-content flex w-100'>
          {menu}
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/client/icons/cancel.svg`}
            alt='close'
            className='navbar-close pointer mt-20'
            onClick={closeNavbar}
          />
        </div>
      </div>
    );

  if (!open)
    return (
      <div className='navbar'>
        <div className='logo pointer' onClick={() => history.push('/')}>
          <img src={logo_src} alt='' />
        </div>
        {isTabletOrMobile ? (
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/client/icons/menu.svg`}
            alt='open menu'
            className='pointer button-open'
            onClick={expandNavbar}
          />
        ) : (
          menu
        )}
      </div>
    );
};

export default Navbar;
