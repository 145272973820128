import React, { useState } from 'react';

const ScrollUp = () => {
  const [show, setShow] = useState(false);

  document.addEventListener('scroll', (e) => {
    if (window.scrollY > window.innerHeight) {
      setShow(true);
    } else {
      setShow(false);
    }
  });

  if (show)
    return (
      <div className='pointer scroll-up' onClick={() => window.scrollTo(0, 0)}>
        <img
          src={`${process.env.REACT_APP_SERVER_URL}/client/icons/up-arrow.svg`}
          className='w-100'
          alt='go to the top'
        ></img>
      </div>
    );

  return null;
};

export default ScrollUp;
