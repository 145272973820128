import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useAuth from '../../../hooks/useAuth';
import { useHistory } from 'react-router-dom';

const Login = () => {
  const { isAdmin, setAuth } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (isAdmin) {
      history.push('/admin/dashboard');
    }
  });

  const [invalid, setInvalid] = useState(false);
  const [field, setField] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    setField({
      ...field,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post('/api/login', {
        username: field.username,
        password: field.password,
      });

      setAuth('login');
    } catch (error) {
      setInvalid(true);
    }
  };

  return (
    <div className='container content login flex main-page'>
      <form onSubmit={handleSubmit}>
        <div>
          <label className='montserrat-light' htmlFor='username'>
            Username
          </label>
          <input
            type='text'
            id='username'
            name='username'
            onChange={handleChange}
          />
        </div>
        <div>
          <label className='montserrat-light' htmlFor='password'>
            Password
          </label>
          <input
            type='password'
            id='password'
            name='password'
            onChange={handleChange}
          />
        </div>
        {invalid && (
          <div className='montserrat error-message red'>
            Username o password errati.
          </div>
        )}

        <div className='flex'>
          <button className='montserrat button' type='submit'>
            Login
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
