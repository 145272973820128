import React from 'react';
import '../../../../node_modules/slick-carousel/slick/slick.css';
import '../../../../node_modules/slick-carousel/slick/slick-theme.css';

import Title from './Title';
import Previews from './Previews';
import ProjectsSlides from './ProjectsSlides';

const HomeScreen = () => {

  return (
    <>
      <Title />
      <Previews />
      <ProjectsSlides />

      <div className='home-logo flex'>
        <img
          src={`${process.env.REACT_APP_SERVER_URL}/client/icons/claudiabettini-lighting-designer-logo-home.svg`}
          alt='logo claudia bettini lighting designer'
        />
      </div>
    </>
  );
};

export default HomeScreen;
