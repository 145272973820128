import React from 'react';

const ErrorScreen = () => {
  return (
    <div className='container main-page content error'>
      <h1 className='montserrat'>Si è verificato un errore</h1>
      <p className='montserrat mt-20'>
        Ci scusiamo per il disagio. Se il problema persiste, rivolgersi
        all'amministratore del sistema.
      </p>
      <p className='montserrat'>
        Nel frattempo, puoi cliccare{' '}
        <strong>
          <a href='/'>qui</a>
        </strong>{' '}
        per tornare alla home.
      </p>
    </div>
  );
};

export default ErrorScreen;
