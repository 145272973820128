import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Redirect, useParams } from 'react-router';
import { useGetProjectsQuery } from '../../../redux/api';
import Loader from '../../Loader';
import PhotoSwipe from 'photoswipe/dist/photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import Photoswipe from './Photoswipe';
import Slider from 'react-slick';
import Tags from '../../Tags';

const ProjectPage = () => {
  const params = useParams();
  const { data: projects, error } = useGetProjectsQuery();

  const getProject = (projects) => {
    return projects[params.tag].filter(
      (project) => project._id === params.id
    )[0];
  };

  const getImages = (projects) => {
    const project = getProject(projects);
    const images = project.images.filter((image) => {
      return image.width > image.height;
    });
    return images;
  };

  const handleClick = (projects) => {
    const pswpElement = document.querySelectorAll('.pswp')[0];
    const items = [...getProject(projects).images];
    items.sort((a, b) => (a.order > b.order ? 1 : -1));
    const preparedItems = items.map((image) => {
      return {
        src: `${process.env.REACT_APP_SERVER_URL}/images/${image.src}`,
        w: parseInt(image.width, 10),
        h: parseInt(image.height, 10),
      };
    });
    let gallery = new PhotoSwipe(
      pswpElement,
      PhotoSwipeUI_Default,
      preparedItems
    );
    gallery.init();
  };

  if (projects)
    return (
      <>
        <div className='container main-page'>
          <Tags redirect />
          <div className='project-page montserrat-light'>
            <h1 className='montserrat-medium'>
              {getProject(projects).infos.title}
            </h1>
            <h2 className='pb-small'>{getProject(projects).infos.subtitle}</h2>
            <ReactMarkdown linkTarget='_blank' className='pb-large'>
              {getProject(projects).infos.description}
            </ReactMarkdown>
            {getImages(projects).length > 0 && (
              <div className='gallery-container'>
                <Slider
                  slidesToShow={1}
                  slidesToScroll={1}
                  speed={1000}
                  infinite={true}
                  arrows={false}
                  autoplay={true}
                  autoplaySpeed={5000}
                  dots={false}
                  draggable={false}
                  pauseOnHover={false}
                  swipe={true}
                  swipeToSlide={true}
                  touchMove={false}
                >
                  {getImages(projects).map((image) => {
                    return (
                      <div key={image.order}>
                        <img
                          src={`${process.env.REACT_APP_SERVER_URL}/images/${image.src}`}
                          alt=''
                          className='w-100 opaque hover-opaque pointer'
                          onClick={() => handleClick(projects)}
                        />
                      </div>
                    );
                  })}
                </Slider>

                <h1 className='view-gallery montserrat'>Gallery</h1>
              </div>
            )}
          </div>
        </div>
        <Photoswipe />
      </>
    );

  if (error) return <Redirect push to={'/error'} />;

  return (
    <div className='container main-page content flex'>
      <Loader />
    </div>
  );
};

export default ProjectPage;
