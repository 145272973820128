import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectTag } from '../../../../redux/tagSlice';

import useAuth from '../../../../hooks/useAuth';
import useError from '../../../../hooks/useError';
import ProjectCard from './ProjectCard';
import Loader from '../../../Loader';
import Tags from '../../../Tags';

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const tag = useSelector(selectTag);
  const handleError = useError();
  const { redirectUnauthenticated } = useAuth();

  redirectUnauthenticated();

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axios.get('/api/projects/all', {
          withCredentials: true,
        });
        setProjects(data);

        setLoading(false);
      } catch (error) {
        handleError(error);
      }
    }
    fetchData();
  }, [refetch]);

  const changeVisibility = async (project) => {
    setLoading(true);

    try {
      await axios.patch(
        `/api/projects/${project._id}/visibility`,
        { visible: !project.visible },
        { withCredentials: true }
      );

      setRefetch(!refetch);
    } catch (error) {
      handleError(error);
    }
  };

  const changeOrder = async (project, newIndex) => {
    setLoading(true);

    // Recreate order array and remove id from it
    const oldOrder = projects[project.tag].visible.map((project) => {
      return project._id;
    });
    let newOrder = oldOrder.filter((id) => {
      return id !== project._id;
    });
    // Insert id in the new position
    newOrder.splice(newIndex, 0, project._id);

    try {
      await axios.put(
        `/api/projects/order`,
        {
          order: newOrder,
          tag: project.tag,
        },
        { withCredentials: true }
      );
      setRefetch(!refetch);
    } catch (error) {
      handleError(error);
    }
  };

  const handleDelete = async (project) => {
    const message = `Sicura? Eliminare un progetto è un'azione permanente! Se desideri procedere, scrivi il nome del progetto (${project.infos.title.trim()}) e premi invio`;
    const confirmationName = prompt(message);

    if (confirmationName.trim() === project.infos.title.trim()) {
      setLoading(true);

      try {
        await axios.delete(`/api/projects/${project._id}`, {
          withCredentials: true,
        });
        setRefetch(!refetch);
      } catch (error) {
        handleError(error);
      }
    }
  };

  const projectProps = {
    changeVisibility,
    changeOrder,
    handleDelete,
  };

  if (loading) {
    return (
      <div className='container main-page flex'>
        <Loader />
      </div>
    );
  } else {
    return (
      <div className='container main-page mt-20 pb-small'>
        <Tags />

        {projects[tag]?.visible.length === 0 &&
          projects[tag]?.hidden.length === 0 && (
            <div className='flex pt-large'>
              <p className='montserrat-medium'>
                Ooops, sembra che qui non ci sia ancora nulla
              </p>
            </div>
          )}

        {projects[tag]?.visible.length > 0 && (
          <div className='dashboard'>
            <h1 className='montserrat flex'>PUBBLICATI</h1>
            <div className='grid'>
              {projects[tag]?.visible.map((project, index) => {
                return (
                  <ProjectCard
                    key={project._id}
                    project={project}
                    totalProjects={projects[tag]?.visible.length}
                    order={index}
                    {...projectProps}
                  />
                );
              })}
            </div>
          </div>
        )}

        {projects[tag]?.hidden.length > 0 && (
          <div className='dashboard'>
            <h1 className='montserrat flex mt-20'>NASCOSTI</h1>
            <div className='grid'>
              {projects[tag]?.hidden.map((project) => {
                return (
                  <ProjectCard
                    key={project._id}
                    project={project}
                    {...projectProps}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default Dashboard;
