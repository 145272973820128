import React from 'react';
import { useHistory } from 'react-router';
import Slider from 'react-slick';

const Previews = () => {
  const history = useHistory();

  const redirectToProjects = () => {
    history.push('/portfolio');
  };

  const redirectToProfile = () => {
    history.push('/profilo');
  };

  function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className}>
        <img
          src={`${process.env.REACT_APP_SERVER_URL}/client/icons/right-arrow-white.svg`}
          onClick={onClick}
          alt='next'
          className='w-100'
        />
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className}>
        <img
          src={`${process.env.REACT_APP_SERVER_URL}/client/icons/left-arrow-white.svg`}
          onClick={onClick}
          alt='previous'
          className='w-100'
        />
      </div>
    );
  }

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    draggable: false,
    swipe: true,
    swipeToSlide: true,
    touchMove: false,
    cssEase: 'linear',
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const getSlides = (type) => {
    const slides = [];
    for (let i = 0; i < 3; i++) {
      slides.push(
        <div className='pointer' key={i}>
          <img
            src={`${
              process.env.REACT_APP_SERVER_URL
            }/client/home/claudiabettini-lighting-designer-preview-${
              type === 'profile' ? 'left' : 'right'
            }-0${i + 1}.jpg`}
            alt=''
            className='w-100'
          />
        </div>
      );
    }

    return (
      <Slider {...settings} className={`preview-img ${type}-preview-img`}>
        {slides}
      </Slider>
    );
  };

  return (
    <div className='container previews'>
      <div className='profile-preview pb-large'>
        {getSlides('profile')}
        <div
          className='montserrat preview-text profile-preview-text pointer'
          onClick={redirectToProfile}
        >
          <h2 className='montserrat-medium pointer'>
            Architetto
            <br />
            Lighting Designer
          </h2>
          <p>Progetti di riqualificazione e valorizzazione illuminotecnica</p>
          <p>Progettazione illuminotecnica per nuove realizzazioni</p>
          <p>Verifiche illuminotecniche</p>
          <p>Simulazioni degli effetti luminosi</p>
          <p>Direzione artistica</p>
          <br />
          <p>Venezia, Padova, Treviso, Vicenza</p>
        </div>
      </div>

      <div className='projects-preview pb-large'>
        {getSlides('projects')}

        <div
          className='preview-text projects-preview-text montserrat pointer'
          onClick={redirectToProjects}
        >
          <h2 className='montserrat-medium pointer'>
            Il progetto
            <br />
            di illuminazione
          </h2>
          <p>Una luce misurata, progettata</p>
          <p>Segni di luce che disegnano lo spazio</p>
          <p>Immaginare atmosfere</p>
          <p>Modulare scenari luminosi</p>
          <p>Luce scenografica, di valorizzazione</p>
          <br />
          <p>Luce è emozione e precisione, tecnica e poesia</p>
        </div>
      </div>
    </div>
  );
};

export default Previews;
